// Price toggle
(function ($) {
  new Vue({
    el: '#pricetoggle',
    store: ConfStore,
    computed: {
      hideEndPrice: function() {
        return this.$store.state.hideEndPrice;
      }
    },
    methods: {
      toggleEndPrice: function () {
        this.$store.commit('toggle_end_price');
      },
    }
  });
})(jQuery);
